.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .parentList,
  .childList {
    width: 50%;
    padding: 10px;

    & h1 {
      text-align: center;
    }
  }
  .childList {
    & input {
      width: 100%;
      padding: 5px;
      margin: 5px 0;
      font-size: 25px;
    }
  }
}
.empty {
  text-align: center;
  border: 1px solid;
  background-color: rgb(255, 0, 0);
  color: white;
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  font-size: 25px;
  border-radius: 12px;
}