.skeleton {
  width: 290px;
  height: 150px;
  background: #ffff;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.2);
  padding: 12px 10px;
  margin: 15px;
  transition: all 300ms;
  border-radius: 12px;
}

.skeleton_load {
  width: 70px;
  height: 70px;
  float: left;
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  border-radius: 50%;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
}

.skeleton_load_extreme_title {
  width: 190px;
  height: 20px;
  float: right;
  border-radius: 5px;
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
}

.skeleton_load_extreme_descripion {
  width: 190px;
  height: 95px;
  float: right;
  border-radius: 5px;
  background: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  margin-top: 10px;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
}

@keyframes load89234 {
  100% {
    background-position: -100% 0;
  }
}

