@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Bebas Neue", cursive;
}

.page {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
