header {
  background-color: #000000;
  width: 100%;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 60%;
      font-size: 30px;
      color: white;
      text-decoration: none;

      span {
        color: red;
        font-weight: 500;
        margin-right: 3px;
      }
    }
    nav {
      display: flex;
    }

    button {
      background-color: transparent;
      border: 1px solid white;
      color: white;

      font-size: 25px;
      padding: 0px 10px;
      margin: 0 5px;
      transition: 0.3s;
      cursor: pointer;
    }
  }
}

.wrapper {
  button:hover {
    background-color: white;
    color: black;
  }
}
