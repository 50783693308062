footer {
  background-color: black;
  padding: 10px;
  margin-top: auto;

  .title {
    color: white;
    text-align: center;
    font-weight: 500;
  }
}
