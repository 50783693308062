.parent_block {
    width: 90%;
    background-color: black;
    color: white;
    margin: 10px;
    padding: 5px;
    border-radius: 12px;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
  }