.sort_options_block {
  display: flex;
}
.sort_order_toggle {
  margin: 0 5px;
  width: 35px;
  height: 35px;
  border: 1px solid #ccc;
  background-color: transparent;
}
.child_block {
  border: 1px solid;
  background-color: transparent;
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  font-size: 25px;
  border-radius: 12px;
}
